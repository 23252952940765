/***** BASE IMPORTS *****/
import { Store, useStore } from '@tanstack/react-store'

/***** TYPE DEFINITIONS *****/
type TViewport = keyof typeof MediaStore.mediaResolutions
type TUseAppViewport = <Match extends TViewport[] | undefined = undefined>(match?: Match) => Match extends undefined ? TViewport : boolean

/***** COMPONENT START *****/
class MediaStore {
    private static store = new Store<{ viewport: TViewport }>({ viewport: 'xs' })

    public constructor() {
        MediaStore.setInitialViewport()
        MediaStore.initializeMediaListeners()
    }

    public useAppViewport: TUseAppViewport = (match?: Array<TViewport>) => {
        const { viewport } = useStore(MediaStore.store)

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!match) return viewport as any

        return match.includes(viewport)
    }

    private static setInitialViewport = () => {
        // Set initial viewport
        Object.entries(MediaStore.mediaResolutions).forEach(([viewport, resolution]) => {
            if (window.matchMedia(resolution).matches) {
                MediaStore.store.setState(() => ({ viewport: viewport as TViewport }))
            }
        })
    }

    private static initializeMediaListeners = () => {
        // Listen for media changes
        Object.entries(MediaStore.mediaResolutions).forEach(([viewport, resolution]) => {
            if (typeof window.matchMedia(resolution).addListener === 'function') {
                window.matchMedia(resolution).addListener(MediaStore.handleMediaChange(viewport as TViewport))
            } else {
                window.matchMedia(resolution).addEventListener('change', MediaStore.handleMediaChange(viewport as TViewport))
            }
        })
    }

    private static handleMediaChange = (viewport: TViewport) => (e: MediaQueryListEvent) => {
        if (e.matches) {
            MediaStore.store.setState(() => ({ viewport }))
        }
    }

    public static mediaResolutions = {
        xs: '(max-width: 380px)',
        sm: '(min-width: 381px) and (max-width: 640px)',
        md: '(min-width: 641px) and (max-width: 768px)',
        lg: '(min-width: 769px) and (max-width: 1024px)',
        xl: '(min-width: 1025px) and (max-width: 1280px)',
        '2xl': '(min-width: 1281px) and (max-width: 1536px)',
        '3xl': '(min-width: 1537px) and (max-width: 2160px)',
        '4xl': '(min-width: 2161px)'
    } as const
}

export const { useAppViewport } = new MediaStore()
