import { App } from 'App/app'
import { initialiseCypress, initialisePolyfills } from 'initialise'
import { CaptchaProvider } from 'providers/captcha'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import 'react-phone-input-2/lib/high-res.css'
import { Provider } from 'react-redux'
import { store } from 'store/store'
import reportWebVitals from './reportWebVitals'

/**********************************************************************************************************
 *   INITIALIZE APPLICATION
 **********************************************************************************************************/
initialiseCypress()
initialisePolyfills()

/**********************************************************************************************************
 *   ROOT RENDER
 **********************************************************************************************************/
const rootElement = document.getElementById('app-root') as HTMLElement
const root = createRoot(rootElement)

root.render(
    <Provider store={store}>
        <CaptchaProvider>
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </CaptchaProvider>
    </Provider>
)

/**********************************************************************************************************
 *   PERFORMANCE MEASUREMENT
 **********************************************************************************************************/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
