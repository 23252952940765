import { shopAPI } from 'api/shop'
import { useMemo } from 'react'

/**********************************************************************************************************
 *   HOOK START
 **********************************************************************************************************/
/**
 * retrives the tld list from localStorage and ensures that it is the correct type
 */
export const useTLDList = () => {
    /***** QUERIES *****/
    const { data } = shopAPI.useDomainProductListQuery(undefined)

    // Memoize tldlist to ensure that a static reference is returned for consuming components
    const tldList = useMemo(() => data?.map(({ name }) => name), [data])

    if (!Array.isArray(tldList)) return null
    if (tldList.some((tld) => typeof tld !== 'string')) return null

    return tldList as Array<string>
}
/**********************************************************************************************************
 *   HOOK END
 **********************************************************************************************************/

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * renders the children if there are multiple tlds available
 */
export const RenderWhileMultipleTLDsAvailable = ({ children }: { children: React.ReactNode }) => {
    const tldList = useTLDList()

    return Number(tldList?.length) > 1 ? <>{children}</> : null
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
