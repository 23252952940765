/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import {
    ChartBarSquareIcon,
    ChatBubbleLeftRightIcon,
    DocumentArrowDownIcon,
    DocumentTextIcon,
    GlobeAltIcon,
    HomeIcon,
    MagnifyingGlassCircleIcon,
    QuestionMarkCircleIcon,
    ServerIcon,
    ShoppingBagIcon,
    UserIcon
} from '@heroicons/react/24/solid'
import { AnimatePresence } from 'framer-motion'
import { ReactNode, Suspense, useEffect, useRef } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

/**********************************************************************************************************
 *   COMPONENT IMPORTS
 **********************************************************************************************************/
import LinkPaymentReturnLightbox from 'App/linkPaymentReturnLightbox'
import { DelayedLoader, Loader } from 'components/loader'
import { Frame, SidenavItem, Theme } from 'nxui/src'

/**********************************************************************************************************
 *   CONTAINERS
 **********************************************************************************************************/
import Invoice from 'components/invoice/invoice'
import SwitchAccount from 'containers/authentication/lightbox/auth.accounts.lightbox'
import Support from 'containers/support/support'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { useLogoutMutation } from 'api/authentication'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setRedirect, setSwitchAccountOpen, triggerRedirect } from 'store/slices/appSlice'
import { setSupportOpen } from 'store/slices/supportSlice'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import { ShopRouteBuilder } from 'containers/shop/helpers/routeMatchers'
import { titleCase } from 'helpers/utils'
import { Helmet } from 'react-helmet-async'
import { getActiveSections } from 'router/userRouter'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { Header } from '../components/header'
import { useAppViewport } from '../helpers/hooks/useAppViewport'
import { websiteRoutes } from './consts'
import { GuestRoute } from './guestRouter.styles'

/**********************************************************************************************************
 *   STYLING
 **********************************************************************************************************/
const UserRoute = {
    Frame: styled(Frame)`
        height: 100%;
        overflow: auto;

        /* Selectors cannot use classnames since the internal SCs are not exposed */
        & > div > ul > li > img {
            ${({ theme }) => Theme.Functions.mediaBreakDown(theme['breakpoints-lg'])} {
                display: none;
            }
        }

        & nav header {
            margin: 15px;
            max-width: 228px;

            button img {
                max-height: 144px;
                max-width: 228px;
                margin: 0 auto;
            }
        }
    `
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function UserRoot() {
    /***** HOOKS *****/
    const headerRef = useRef<HTMLElement>(null)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const isMobile = useAppViewport(['xs', 'sm', 'md'])
    const {
        appSession: { user, current_account },
        appAuthentication: { authorisedAccounts },
        appActiveRouter,
        appSettings: {
            provider: { provider_name, application_name, logo_url, url },
            section
        },
        appLinkTypePaymentState: { orderId }
    } = useAppSelector((state) => state.app)

    /***** QUERIES *****/
    const [logout] = useLogoutMutation({ fixedCacheKey: 'logout' })

    /***** EFFECTS *****/
    useEffect(() => {
        if (orderId) {
            navigate(ShopRouteBuilder.orderId(orderId))
        }
    }, [])

    useEffect(() => {
        if ((Object.values(websiteRoutes) as Array<string>).includes(pathname)) {
            dispatch(setRedirect(false))
            headerRef.current?.scrollIntoView()
        }
    }, [pathname])

    useEffect(() => {
        if (appActiveRouter.meta.redirect) {
            dispatch(triggerRedirect(navigate))
        }
    }, [appActiveRouter.meta.redirect])

    /***** RENDER HELPERS *****/
    function checkLocation(link: string) {
        const [, section] = pathname.split('/')

        return link.includes(section)
    }

    function buildNavigation() {
        if (section && typeof section === 'object') {
            const navigation: Array<ReactNode> = []
            const accountNavigation: Array<ReactNode> = []
            const defaultNavigation: Array<ReactNode> = []
            const serviceNavigation: Array<ReactNode> = []
            const shopNavigation: Array<ReactNode> = []

            const activeSections = getActiveSections(section)

            Object.keys(section).forEach((key) => {
                if (!activeSections.includes(key)) return

                switch (key) {
                    case 'account':
                        return accountNavigation.push(
                            <SidenavItem key={key} active={checkLocation('/account')}>
                                <Link to={'/account'}>
                                    <UserIcon width={'20px'} />
                                    <span>Account</span>
                                </Link>
                            </SidenavItem>
                        )
                    case 'billing':
                        return accountNavigation.push(
                            <SidenavItem key={key} active={checkLocation('/billing')}>
                                <Link to={'/billing'}>
                                    <DocumentTextIcon width={'20px'} />
                                    <span>Billing</span>
                                </Link>
                            </SidenavItem>
                        )
                    case 'dashboard':
                        return defaultNavigation.push(
                            <SidenavItem key={key} active={checkLocation('/dashboard')}>
                                <Link to={'/dashboard'}>
                                    <ChartBarSquareIcon width={'20px'} />
                                    <span>Dashboard</span>
                                </Link>
                            </SidenavItem>
                        )
                    case 'domain':
                        return serviceNavigation.push(
                            <SidenavItem key={key} active={checkLocation('/domains')}>
                                <Link to={'/domains'}>
                                    <GlobeAltIcon width={'20px'} />
                                    <span>Domains</span>
                                </Link>
                            </SidenavItem>
                        )
                    case 'service':
                        return serviceNavigation.push(
                            <SidenavItem key={key} active={checkLocation('/services')}>
                                <Link to={'/services'}>
                                    <ServerIcon width={'20px'} />
                                    <span>Services</span>
                                </Link>
                            </SidenavItem>
                        )
                    case 'shop':
                        return shopNavigation.push(
                            <SidenavItem key={key} active={checkLocation('/shop/purchase')}>
                                <Link to={'/shop/purchase'}>
                                    <ShoppingBagIcon width={'20px'} />
                                    <span>Shop</span>
                                </Link>
                            </SidenavItem>
                        )
                    case 'support':
                        return accountNavigation.push(
                            <SidenavItem key={key}>
                                <button type={'button'} onClick={() => dispatch(setSupportOpen(true))}>
                                    <ChatBubbleLeftRightIcon width={'20px'} />
                                    <span>Support</span>
                                </button>
                            </SidenavItem>
                        )
                    default:
                        break
                }
            })

            defaultNavigation.length > 0 && navigation.push(defaultNavigation)
            serviceNavigation.length > 0 && navigation.push(serviceNavigation)
            accountNavigation.length > 0 && navigation.push(accountNavigation)
            shopNavigation.length > 0 && navigation.push(shopNavigation)

            return navigation
        }

        return []
    }

    function getLogoURL() {
        if (!logo_url) return null
        if (logo_url.startsWith('http')) return logo_url
        return `${url}/${logo_url}`
    }

    function getPageTitle() {
        const path = pathname.split('/')
        const page = path.length >= 3 && path[1] === 'login' ? path[2] : path[1]

        // Handle website routes
        if (section.website) {
            if (Object.values(websiteRoutes).find((route) => route === pathname)) {
                const title = path[1] === '' ? 'Home' : path[1]
                return `${titleCase(title)} - ${application_name}`
            }
        }

        return path.length === 0 || path.every((pathname) => pathname === '') ? 'Loading...' : `${titleCase(page)} - ${application_name}`
    }

    function renderHelmet() {
        return (
            <Helmet>
                <title>{getPageTitle()}</title>
            </Helmet>
        )
    }

    if (section.website && ((Object.values(websiteRoutes) as Array<string>).includes(pathname) || pathname.includes('/shop'))) {
        return (
            <GuestRoute.Outer>
                {renderHelmet()}
                <GuestRoute.Container>
                    <Header
                        ref={headerRef}
                        type={section.website ? 'sidebar' : 'original'}
                        logo={<Header.Logo src={logo_url} alt={provider_name} />}
                        login={
                            !!section.login && (
                                <GuestRoute.HeaderButton size='small' to='/login'>
                                    <Header.Action />
                                    {!isMobile && <GuestRoute.HeaderActionText>Dashboard</GuestRoute.HeaderActionText>}
                                </GuestRoute.HeaderButton>
                            )
                        }
                    >
                        <Header.Nav>
                            <Header.Nav.Item path={websiteRoutes.ROOT} mobileIcon={HomeIcon} exact permanent>
                                Home
                            </Header.Nav.Item>
                            <Header.Nav.Item path={websiteRoutes.DOMAIN_PRICING} mobileIcon={GlobeAltIcon} exact permanent>
                                Domain Names
                            </Header.Nav.Item>
                            <Header.Nav.Item path={websiteRoutes.ABOUT_US} mobileIcon={QuestionMarkCircleIcon} exact permanent>
                                About Us
                            </Header.Nav.Item>
                            <Header.Nav.Item path={websiteRoutes.TERMS_OF_SERVICE} mobileIcon={DocumentArrowDownIcon} exact>
                                Legal Document
                            </Header.Nav.Item>
                            <Header.Nav.Item path={websiteRoutes.WHOIS} mobileIcon={MagnifyingGlassCircleIcon} exact>
                                Whois
                            </Header.Nav.Item>
                            <Header.Nav.Item path='/shop' mobileIcon={ShoppingBagIcon} permanent>
                                Shop
                            </Header.Nav.Item>
                        </Header.Nav>
                    </Header>
                    <AnimatePresence mode={'wait'}>
                        <Suspense
                            fallback={
                                <DelayedLoader delay={300}>
                                    <Loader width={70} height={70} message={'Fetching Information'} />
                                </DelayedLoader>
                            }
                        >
                            <Outlet />
                        </Suspense>
                    </AnimatePresence>
                </GuestRoute.Container>
            </GuestRoute.Outer>
        )
    }

    /***** RENDER *****/
    return (
        <>
            {renderHelmet()}
            <UserRoute.Frame
                company={{
                    src: getLogoURL(),
                    alt: provider_name,
                    application: application_name,
                    navigate: () => navigate('/dashboard?page=1')
                }}
                profile={{
                    user,
                    account: {
                        name: current_account?.account ? current_account.account.account_holder : '',
                        company: current_account?.account ? current_account.account.company_name : '',
                        account_number: current_account?.account ? current_account.account.account_number : '',
                        role: {
                            name: current_account?.role ? current_account.role.name : ''
                        }
                    },
                    redirect: () => navigate('/account')
                }}
                support={() => dispatch(setSupportOpen(true))}
                shop={() => console.log('shop')}
                nav={buildNavigation()}
                content={{
                    render: (
                        <AnimatePresence mode={'wait'}>
                            <Suspense
                                fallback={
                                    <DelayedLoader delay={300}>
                                        <Loader width={70} height={70} message={'Fetching Information'} />
                                    </DelayedLoader>
                                }
                            >
                                <Outlet />
                                <Invoice />
                                <Support />
                                <SwitchAccount />
                                <LinkPaymentReturnLightbox />
                            </Suspense>
                        </AnimatePresence>
                    )
                }}
                logout={() => logout()}
                switchAcc={authorisedAccounts && authorisedAccounts.length > 1 ? () => dispatch(setSwitchAccountOpen(true)) : null}
            />
        </>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
