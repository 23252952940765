/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useAppSelector } from 'store/hooks'

/**********************************************************************************************************
 *   COMPONENTS/PAGES
 **********************************************************************************************************/
import { WebsiteHero } from 'containers/website/components/Hero'
import { WebsiteDomainFeatures } from 'containers/website/components/features'
import { Footer } from 'containers/website/components/footer'
import { CompetitivePrices } from './competitive'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteHomeStyles as S } from './style'

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const Home = () => {
    /***** HOOKS *****/
    const { hero_title = '', hero_subtitle = '' } = useAppSelector((store) => store.app.appSettings.website) ?? {}

    /***** RENDER *****/
    return (
        <S.Container>
            <WebsiteHero title={hero_title} subtitle={hero_subtitle}>
                <WebsiteHero.Search />
            </WebsiteHero>
            <CompetitivePrices />
            <WebsiteDomainFeatures />
            <Footer />
        </S.Container>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
