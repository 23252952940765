/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Form } from 'formik'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

/**********************************************************************************************************
 *   COMPONENT IMPORT
 **********************************************************************************************************/
import { Anchor, Button, Fade, Theme } from 'nxui/src'

/**********************************************************************************************************
 *   INTERFACES / ENUMS
 **********************************************************************************************************/
import { Weight } from 'models/enums'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
export const Auth = {
    Base: styled(Fade)`
        flex-flow: column nowrap;
        height: auto;
        width: 100%;
        min-width: 400px;
        max-width: 520px;
        padding: 20px;
        margin: 0 auto;
        border-radius: 16px;
        background: ${(props) => props.theme['background--000']};
        box-shadow: 0px 0px 2.1px ${(props) => Theme.Functions.hexToRGBA(props.theme['black--100'], '0.05')},
            0px 0px 5.5px ${(props) => Theme.Functions.hexToRGBA(props.theme['black--100'], '0.033')},
            0px 0px 13.3px ${(props) => Theme.Functions.hexToRGBA(props.theme['black--100'], '0.025')},
            0px 0px 50px ${(props) => Theme.Functions.hexToRGBA(props.theme['black--100'], '0.017')};

        &.signup {
            max-width: 720px;
        }
    `,
    Fade: styled(Fade)`
        flex-flow: column nowrap;
        height: unset;
    `,
    Loading: styled.div`
        min-height: 140px;
    `,
    Wrapper: styled.div``,
    Form: styled(Form)`
        padding: 10px 15px;
        border-radius: 8px;
    `,
    Row: styled.div`
        display: inline-flex;
        flex-flow: row nowrap;
        width: 100%;
        padding: 0 15px;
        font-size: 1.2rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Poppins', Weight.SemiBold)};
    `,
    Existing: styled.div``,
    Border: styled.div`
        border-top: 1px solid ${(props) => props.theme[`border--100`]};
    `,
    Break: styled.div`
        height: 30px;
    `,
    New: styled.div``,
    Title: styled.h2`
        display: inline-flex;
        flex-flow: row nowrap;
        padding: 10px 15px 0px;
        width: 100%;
        font-size: 2.4rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Poppins', Weight.SemiBold)};

        svg {
            margin-right: 10px;
        }
    `,
    Description: styled.p`
        padding: 5px 15px;
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', Weight.Regular)};
    `,
    Back: styled(Anchor)``,
    Reset: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.2rem;
    `,
    Switch: {
        Field: styled.div`
            display: flex;
            flex-direction: row;
            /* justify-content: flex-end; */
            align-items: center;
            margin-top: 20px;
            margin-bottom: 15px;
        `,
        Label: styled.label`
            width: fit-content;
            margin-right: 15px;
            font-size: 1.2rem;
            color: ${(props) => props.theme['text--200']};
            ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
        `
    },
    Submit: styled(Button)`
        width: 100%;
        height: 44px;
        text-align: center;
        margin-left: auto;
        justify-content: center;
    `,
    Resent: styled.div`
        display: flex;
        gap: 5px;
    `,
    Footer: styled.div`
        width: 100%;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-around;
        gap: 5px;
        padding-top: 20px;
        font-size: 1.4rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', Weight.Regular)};
    `,
    Link: styled(Link)`
        color: ${(props) => props.theme['primary--100']};
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
    `,
    Button: styled.button`
    color: ${(props) => props.theme['primary--100']};
    ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
`,
    Lightbox: {
        Base: styled.div`
            padding: 20px;
            background-color: ${(props) => props.theme[`background--000`]};
        `,
        Footer: styled.footer`
            background-color: ${(props) => props.theme[`background--100`]};
            padding: 12px 16px;
            display: flex;
            flex-direction: column-reverse;

            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding-left: 16px;
                padding-right: 16px;
            }
        `,
        Links: styled.div`
            display: flex;
            align-items: center;
            margin-right: auto;
            ${(props) => Theme.Functions.mediaBreakDown(props.theme[`breakpoints-sm`])} {
                width: 100%;
                justify-content: center;
            }
        `,
        Actions: styled.div`
            display: flex;
            flex-direction: column;
            ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                flex-direction: row;
            }
            > button {
                width: 100%;
                ${(props) => Theme.Functions.mediaBreakUp(props.theme[`breakpoints-sm`])} {
                    width: auto;
                }
            }
        `
    }
}

export const SignUp = {
    Form: styled(Form)`
        width: 100%;
        padding: 0 1.5rem;

        ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-sm'])} {
            padding: 0 1.5rem;
        }
    `,
    Body: styled.div``,
    Section: styled.div``,
    Row: styled.div`
        display: flex;
        flex-direction: column;

        > div {
            width: 100%;

            &:first-of-type {
                margin-right: 0rem;
            }
        }

        ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-sm'])} {
            flex-direction: row;

            > div:first-of-type {
                margin-right: 0.5rem;
            }
        }
    `,
    RowSingleFieldHalfWidth: styled.div`
        > div {
            width: 100%;
        }

        ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-sm'])} {
            > div {
                width: calc(50% - 0.25rem);
            }
        }
    `,

    Checkbox: styled.div``,
    Footer: styled.footer`
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 20px;
        height: 68px;
        background: ${(props) => props.theme['background--100']};
        border-radius: 0 0 16px 16px;
    `,
    Title: styled.div<{ top?: boolean }>`
        color: ${(props) => props.theme['text--300']};
        font-size: 1.8rem;
        padding: 1rem 0rem;
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};

        ${(props) =>
            props.top &&
            css`
                border-radius: 16px 16px 0 0;
                overflow: hidden;
            `};
    `,
    Logo: styled.div`
        display: inline-flex;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        right: 7px;
        position: relative;
    `,
    Anchor: styled(Anchor)`
        margin: 5px 0 20px;
        color: ${(props) => props.theme['text--100']};
    `,
    Registration: {
        Base: styled.div`
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow: auto;
        `,
        Company: styled.div`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 2rem auto;
        `,
        Title: styled.span`
            margin: 0 0 0.25rem;
            font-size: 1.4rem;
            padding: 0 1rem;
            text-align: center;
            color: ${(props) => props.theme['text--200']};
            ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
            ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-md'])} {
                margin: 2rem auto 0.25rem;
                padding: 0;
                width: 100%;
                min-width: 425px;
                max-width: 750px;
            }
        `,
        Description: styled.span`
            margin: 0 auto 2rem;
            font-size: 1.4rem;
            padding: 0 1rem;
            text-align: center;
            color: ${(props) => props.theme['text--100']};
            ${Theme.Functions.setFont('Inter', Weight.Regular)};
            ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-md'])} {
                padding: 0;
                width: 100%;
                min-width: 425px;
                max-width: 750px;
            }
        `,
        Form: styled.section`
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 1rem;
            border-radius: 16px;
            background-color: ${(props) => props.theme['background--000']};
            box-shadow: 0 1px 3px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.1`)},
                0 1px 2px ${(props) => Theme.Functions.hexToRGBA(props.theme[`black--100`], `0.06`)};
            ${(props) => Theme.Functions.mediaBreakUp(props.theme['breakpoints-md'])} {
                margin: 0 auto;
                width: 100%;
                min-width: 425px;
                max-width: 750px;
            }
        `,
        Success: styled.div`
            z-index: 2;
            position: absolute;
            top: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            padding: 2rem 0rem;
            border-radius: 16px;
            backdrop-filter: blur(2px);
            background: radial-gradient(circle, ${(props) => props.theme['background--000']} 20%, transparent 100%);
            background-color: ${(props) => props.theme['background--000']};
            ${Theme.Functions.animation(`transition-all`)};
        `,
        Action: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
        `,
        Icon: styled.div`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 96px;
            padding: 18px;
            margin: 0 auto 2rem;
            color: ${(props) => props.theme['white--100']};
            background-color: ${(props) => props.theme['confirm--100']};
            border-radius: 9999px;
        `
    }
}

export const TwoFactor = {
    Resend: styled.div`
        display: flex;
        align-items: center;
        gap: 2px;
        font-size: 1.2rem;
        ${Theme.Functions.setFont('Inter', Weight.Medium)};
    `,
    Recovery: styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 15px;

        > button {
            width: 100%;
        }
    `
}

export const SelectAccount = {
    Container: styled.div`
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        > button {
            width: 100%;
        }
    `,
    Loading: styled.div`
        position: relative;
        bottom: 20px;
        min-height: 220px;
    `,
    Footer: styled.div`
        position: relative;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 20px;
        height: auto;
        background: ${(props) => props.theme['background--100']};
        border-radius: 0 0 16px 16px;
    `
}
