/**********************************************************************************************************
 *   BASE IMPORT
 **********************************************************************************************************/
import { useFormik } from 'formik'

/**********************************************************************************************************
 *   UTILITIES
 **********************************************************************************************************/
import { useTLDList } from 'containers/shop/domain/register/helper'
import Yup from 'helpers/yup'
import { useShopNavigate } from 'router/helpers'

/**********************************************************************************************************
 *   CONSTS
 **********************************************************************************************************/
import { WebsiteHomeStyles as S } from 'containers/website/home/style'
import { regex } from 'helpers/utils'
import type { ValidationError } from 'yup'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
type THeroBannerSearchForm = React.FC<{
    children: React.ReactNode
}>

type ValidationCallback = (value: string) => void | boolean | ValidationError | Promise<boolean | ValidationError>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
/**
 * Form that is used in the HeroBannerSearch component internally, when no custom form provider is provided
 */
export const StandardSearchForm: THeroBannerSearchForm = ({ children }) => {
    /***** HOOKS *****/
    const navigate = useShopNavigate()
    const tlds = useTLDList()

    /***** FUNCTIONS *****/
    const defaultValidationMiddleware = (callback: ValidationCallback) => {
        return (value: string) => {
            if (!tlds) return true
            if (!value.includes('.')) return regex.domain.test(value)
            return callback(value)
        }
    }

    /***** FORM *****/
    const form = useFormik({
        initialValues: {
            search: ''
        },
        validationSchema: Yup.object().shape({
            search: Yup.string()
                .required('You must enter a domain name to search for.')
                .test(
                    'available-tld',
                    'This TLD is currently not supported',
                    defaultValidationMiddleware((value) => {
                        return tlds?.some((tld) => value?.endsWith(tld))
                    })
                )
                .test(
                    'accepted-format',
                    'Please enter a valid domain name',
                    defaultValidationMiddleware((value) => {
                        return regex.domainWithExtension.test(value)
                    })
                )
                .test(
                    'not-subdomain',
                    'Subdomains are not currently supported',
                    defaultValidationMiddleware((value) => {
                        // If the value ends in a known TLD, remove it and then check if there are any dots left. If there are, then it's a subdomain
                        // If it's not ending in a known TLD then it should've failed the check above
                        const sortedTLDs = [...(tlds ?? [])].sort((a, b) => b.length - a.length)

                        for (const tld of sortedTLDs) {
                            if (value.endsWith(tld)) {
                                const regex = new RegExp(`${tld.replaceAll('.', '\\.')}$`)
                                return !value.replace(regex, '').includes('.')
                            }
                        }

                        return true
                    })
                )
        }),
        validateOnChange: false,
        validateOnBlur: false,
        async onSubmit({ search }) {
            await new Promise((resolve) => setTimeout(resolve, 300))

            if (!tlds) return
            if (!search.includes('.')) {
                const [firstTLD] = tlds
                return navigate('/shop/domain-names/purchase', {
                    state: {
                        search: `${search}${firstTLD}`
                    }
                })
            }

            navigate('/shop/domain-names/purchase', {
                state: {
                    search
                }
            })
        }
    })

    /***** RENDER *****/
    return (
        <S.SearchFormikProvider value={form}>
            <form onSubmit={form.handleSubmit}>{children}</form>
        </S.SearchFormikProvider>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
