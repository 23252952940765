/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import type { TypedAddListener, TypedStartListening } from '@reduxjs/toolkit'
import { addListener, createListenerMiddleware } from '@reduxjs/toolkit'
import toast from 'react-hot-toast'

/**********************************************************************************************************
 *   API IMPORTS
 **********************************************************************************************************/
import { billingAPI } from 'api/billing'
import { domainAPI } from 'api/domain'
import { serviceAPI } from 'api/service'

/**********************************************************************************************************
 *   HELPER IMPORTS
 **********************************************************************************************************/
import globalCookies from 'helpers/cookies'
import { AppDispatch, RootState } from 'store/store'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setAppLinkTypePaymentState } from 'store/slices/appSlice'

/**********************************************************************************************************
 *   BASE EXPORTS
 **********************************************************************************************************/
export const billingListenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening = billingListenerMiddleware.startListening as AppStartListening

/**********************************************************************************************************
 *   MAKING PAYMENT WITH PAYPAL OR STRIPE
 **********************************************************************************************************/
const token = globalCookies.get('XSRF-TOKEN')

startAppListening({
    actionCreator: setAppLinkTypePaymentState,
    effect: async ({ payload }, listenerApi) => {
        if (payload.processingStatus === 'in-progress' && payload.paymentType === 'PayPal') {
            let counter = 0

            const interval = setInterval(async () => {
                counter += 1
                const invoiceURL = `${import.meta.env.VITE_API}client/billing/invoices/${payload.invoiceId}`
                const response = await fetch(invoiceURL, { method: 'GET', headers: { 'X-XSRF-TOKEN': token }, credentials: 'include' })
                const { data } = await response.json()

                if (data.status === 'paid') {
                    const success = {
                        detail: `Payment for the invoice #${payload.invoiceId} has been completed.`,
                        type: 'SUCCESS'
                    }
                    // @ts-ignore
                    toast.custom(success, {
                        duration: 5000
                    })
                    listenerApi.dispatch(setAppLinkTypePaymentState({ ...payload, processingStatus: 'finish' }))
                    clearInterval(interval)
                    listenerApi.cancelActiveListeners()
                }

                if (counter === 5) {
                    const error = {
                        detail: 'Fail to process PayPal payment.',
                        type: 'ERROR'
                    }
                    // @ts-ignore
                    toast.custom(error, {
                        duration: 5000
                    })
                    clearInterval(interval)
                    listenerApi.cancelActiveListeners()
                }
            }, 20000)
        }

        if (payload.paymentType === 'Stripe') {
            if (payload.paymentStatus === 'success' && payload.processingStatus === 'in-progress') {
                let counter = 0

                const interval = setInterval(async () => {
                    counter += 1
                    const invoiceURL = `${import.meta.env.VITE_API}client/billing/invoices/${payload.invoiceId}`
                    const response = await fetch(invoiceURL, { method: 'GET', headers: { 'X-XSRF-TOKEN': token }, credentials: 'include' })
                    const { data } = await response.json()

                    if (data.status === 'paid') {
                        const success = {
                            detail: `Payment for the invoice #${payload.invoiceId} has been completed.`,
                            type: 'SUCCESS'
                        }
                        // @ts-ignore
                        toast.custom(success, {
                            duration: 5000
                        })
                        listenerApi.dispatch(setAppLinkTypePaymentState({ ...payload, processingStatus: 'finish' }))
                        clearInterval(interval)
                        listenerApi.cancelActiveListeners()
                    }

                    if (counter === 5) {
                        const error = {
                            detail: 'Fail to process Stripe payment.',
                            type: 'ERROR'
                        }
                        // @ts-ignore
                        toast.custom(error, {
                            duration: 5000
                        })
                        clearInterval(interval)
                        listenerApi.cancelActiveListeners()
                    }
                }, 20000)
            }

            if (payload.paymentStatus === 'canceled') {
                const error = {
                    detail: 'Stripe payment has been canceled.',
                    type: 'ERROR'
                }
                // @ts-ignore
                toast.custom(error, {
                    duration: 5000
                })
                listenerApi.cancelActiveListeners()
            }
        }

        localStorage.removeItem('redirect')
    }
})

/**********************************************************************************************************
 *   PAY INVOICE TO CLEAR CHANGE SERVICE PLAN, RENEW SERVICE PLAN AND RENEW DOMAIN CACHE
 **********************************************************************************************************/
startAppListening({
    matcher: billingAPI.endpoints.payInvoice.matchFulfilled,
    effect: async (_, listenerApi) => {
        listenerApi.dispatch(
            serviceAPI.util.invalidateTags([
                'Service',
                'Services',
                'Change-Plan-Status',
                'Available-Plans',
                'Billing-Cycle',
                'Module-Meta',
                'Renew-Price'
            ])
        )
        listenerApi.dispatch(domainAPI.util.invalidateTags(['Domain-Renewal', 'Domains']))
        listenerApi.cancelActiveListeners()
    }
})

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>
