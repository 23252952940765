import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { useCheckDomainAvailabilityMutation, useCheckDomainSuggestionsMutation, useDomainProductListQuery } from 'api/shop'
import { Register } from 'containers/shop/domain/domain.styles'
import { useLocalStorage } from 'helpers/hooks'
import { regex } from 'helpers/utils'
import { OrderType } from 'models/enums'
import { Combobox, Loader } from 'nxui/src'
import React, { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useShopLocation } from 'router/helpers'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setSearchTerm } from 'store/slices/shopSlice'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
interface Props {
    embedded: boolean
    type: `${OrderType}`
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function DomainSearch({ embedded = false, type }: Props) {
    /***** HOOKS *****/
    const dispatch = useAppDispatch()
    const promo = useAppSelector((state) => state.shop.searchParamPromotion) ?? undefined
    const [tldList] = useLocalStorage('tld', [])
    const { state } = useShopLocation()

    /***** QUERIES *****/
    const [checkDomainAvailability, { isLoading: isCheckDomainAvailabilityLoading, reset: resetCheckDomainAvailability }] =
        useCheckDomainAvailabilityMutation({ fixedCacheKey: 'check-domain-availability' })
    const [, { reset: resetCheckDomainSuggestions }] = useCheckDomainSuggestionsMutation({
        fixedCacheKey: 'check-domain-availability'
    })
    const { isLoading: isDomainProductListLoading } = useDomainProductListQuery()

    /***** STATE *****/
    const [hostnameValue, setHostnameValue] = useState<string>(state?.search?.split('.')[0] ?? '')
    const [error, setError] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')

    const tldRef = document.querySelector('[role="combobox"]') as HTMLInputElement

    const typeTemplates = {
        buttonTitle: type === 'register' && !isCheckDomainAvailabilityLoading ? 'Check Availability' : 'Transfer Domain',
        buttonLoading: type === 'register' && isCheckDomainAvailabilityLoading ? 'Checking Availability...' : 'Validating Domain...',
        domainValidation: type === 'register' ? regex.domain : regex.domainWithExtension
    }

    /***** FUNCTIONS *****/
    function createList(tldList: Array<string>) {
        if (state?.search) {
            const searchTLD = `.${state.search.slice(state.search.indexOf('.') + 1)}`
            if (tldList.includes(searchTLD)) {
                return Array.from(new Set([searchTLD, ...tldList])).map((tld, index) => ({
                    id: `${index}-${tld}`,
                    name: tld
                }))
            }
        }

        if (tldList.includes('.com')) {
            return Array.from(new Set(['.com', ...tldList])).map((tld, index) => ({
                id: `${index}-${tld}`,
                name: tld
            }))
        }

        return tldList.map((tld, index) => ({
            id: `${index}-${tld}`,
            name: tld
        }))
    }

    const handleSubmit = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Enter' && hostnameValue) {
                if (type === 'register') {
                    checkDomainAvailability({ domain: `${hostnameValue}${tldRef?.value}`, promo })
                    dispatch(setSearchTerm(hostnameValue + tldRef?.value))
                } else {
                    checkDomainAvailability({ domain: hostnameValue, promo })
                    dispatch(setSearchTerm(hostnameValue))
                }
            }
        },
        [hostnameValue, tldRef?.value]
    )

    function handleFormSubmit(e: React.SyntheticEvent) {
        e.preventDefault()

        const target = e.target as typeof e.target & {
            domain: { value: string }
            'tld[name]': { value: string }
        }
        const domain = target.domain.value

        if (!error) {
            if (type === 'register') {
                const tld = target['tld[name]'].value
                checkDomainAvailability({ domain: `${domain}${tld}`, promo })
                dispatch(setSearchTerm(domain + tld))
            } else {
                checkDomainAvailability({ domain, promo })
                dispatch(setSearchTerm(domain))
            }
        } else {
            if (domain.length === 0) {
                setError(true)
                setErrorMessage('Domain name is required')
            }

            if (type === 'transfer') {
                setError(!regex.domainWithExtension.test(domain))
                setErrorMessage('Domain name is invalid')
            } else {
                setError(!regex.domain.test(domain))
                setErrorMessage('Domain name is invalid')
            }
        }
    }

    /*   EFFECTS
     *****************************************************/
    useEffect(() => {
        resetCheckDomainAvailability()
        resetCheckDomainSuggestions()

        if (state?.search) {
            checkDomainAvailability({ domain: state.search })
        }
    }, [])

    useEffect(() => {
        if (state?.search) {
            setHostnameValue(state.search.split('.')[0])

            // Prevents rerender when history is changed
            window.history.replaceState({}, '')
        }
    }, [state?.search])

    useEffect(() => {
        window.addEventListener('keydown', handleSubmit)

        return () => {
            window.removeEventListener('keydown', handleSubmit)
        }
    }, [handleSubmit])

    /***** RENDER *****/
    if (embedded) {
        if (tldList.length <= 0 && isDomainProductListLoading) {
            return (
                <Register.LoaderContainer>
                    <Loader.Basic message={'Fetching domain products'} width={48} height={48} />
                </Register.LoaderContainer>
            )
        }

        if (tldList.length <= 0) {
            return <></>
        }

        return (
            <Register.Search>
                <Register.Fields>
                    <Register.DomainField
                        label='Domain Search'
                        name='domain'
                        type='text'
                        prepend={'www.'}
                        disabled={isCheckDomainAvailabilityLoading}
                        onKeyDown={(e: ReactKeyboardEvent) => {
                            if (type === 'register' && e.key === '.') tldRef.select()
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (type === 'register') {
                                setHostnameValue(e.target.value.replace(/[.,\s]/g, ''))
                            } else {
                                setHostnameValue(e.target.value)
                            }
                        }}
                    />
                    {type === 'register' && (
                        <Register.DomainTLD>
                            <Combobox
                                id={'tld'}
                                name={'tld'}
                                label={'TLD'}
                                options={createList(tldList)}
                                disabled={isCheckDomainAvailabilityLoading}
                            />
                        </Register.DomainTLD>
                    )}
                </Register.Fields>
                <Register.DomainSubmit
                    color={'primary'}
                    type={'button'}
                    icon={<MagnifyingGlassIcon />}
                    loading={isCheckDomainAvailabilityLoading && 'Checking availability...'}
                    disabled={isCheckDomainAvailabilityLoading}
                    onClick={() => {
                        if (hostnameValue.length >= 1 && type === 'register') {
                            checkDomainAvailability({ domain: `${hostnameValue}${tldRef?.value}`, promo })
                            dispatch(setSearchTerm(hostnameValue + tldRef?.value))
                        } else {
                            checkDomainAvailability({ domain: hostnameValue, promo })
                            dispatch(setSearchTerm(hostnameValue))
                        }
                    }}
                >
                    {typeTemplates.buttonTitle}
                </Register.DomainSubmit>
            </Register.Search>
        )
    }

    /*   RENDER COMPONENT
     *****************************************************/
    if (tldList.length <= 0 && isDomainProductListLoading) {
        return (
            <Register.LoaderContainer>
                <Loader.Basic message={'Fetching domain products'} width={48} height={48} />
            </Register.LoaderContainer>
        )
    }

    if (tldList.length <= 0) {
        return <></>
    }

    return (
        <Register.Form onSubmit={handleFormSubmit}>
            <Register.Fields>
                <Register.DomainField
                    label={'Domain Search'}
                    name={'domain'}
                    type={'text'}
                    prepend={'www.'}
                    disabled={isCheckDomainAvailabilityLoading}
                    onKeyDown={(e: ReactKeyboardEvent) => {
                        if (type === 'register' && e.key === '.') tldRef.select()
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.target.value.length === 0) {
                            setError(false)
                            setErrorMessage('')
                        } else {
                            if (type === 'transfer') {
                                setError(!regex.domainWithExtension.test(e.target.value) || e.target.value.length === 0)
                                setErrorMessage('Domain name is invalid')
                            } else {
                                setError(!regex.domain.test(e.target.value) || e.target.value.length === 0)
                                setErrorMessage('Domain name is invalid')
                            }
                        }

                        if (type === 'register') {
                            setHostnameValue(e.target.value.replace(/[.,\s]/g, ''))
                        } else {
                            setHostnameValue(e.target.value)
                        }
                    }}
                    value={hostnameValue}
                    error={error && errorMessage.length >= 1}
                    message={errorMessage}
                />
                {type === 'register' && (
                    <Register.DomainTLD>
                        <Combobox id={'tld'} name={'tld'} label={'TLD'} options={createList(tldList)} disabled={isCheckDomainAvailabilityLoading} />
                    </Register.DomainTLD>
                )}
            </Register.Fields>
            <Register.DomainSubmit
                color={'primary'}
                type={'submit'}
                icon={<MagnifyingGlassIcon />}
                loading={isCheckDomainAvailabilityLoading && 'Checking availability...'}
                disabled={isCheckDomainAvailabilityLoading}
            >
                {typeTemplates.buttonTitle}
            </Register.DomainSubmit>
        </Register.Form>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
