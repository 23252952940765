/**********************************************************************************************************
 *   BASE IMPORTS
 **********************************************************************************************************/
import { Field, Form, Formik, FormikProps } from 'formik'
import { useRef } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'

/**********************************************************************************************************
 *   SHARED IMPORTS
 **********************************************************************************************************/
import { Form as NXUIForm } from 'nxui/src'

/**********************************************************************************************************
 *   SLICE IMPORTS
 **********************************************************************************************************/
import { setIsSubmitting, setSupportOpen } from 'store/slices/supportSlice'

/**********************************************************************************************************
 *   HELPERS/STORE IMPORTS
 **********************************************************************************************************/
import globalCookies from 'helpers/cookies'

/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import styled from 'styled-components'

const Support = {
    Form: styled(Form)`
        position: relative;
        width: 100%;
        height: 100%;
    `,
    Body: styled.div`
        padding: 20px 20px 0px;
    `
}

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export default function SupportForm() {
    const dispatch = useDispatch()
    const supportFormRef = useRef<FormikProps<{ subject: string; message: string; type: string; attachments: Array<string | Blob> }>>(null)

    /*   RENDER COMPONENT
     *****************************************************/
    return (
        <Formik
            innerRef={supportFormRef}
            initialValues={{
                subject: '',
                message: '',
                attachments: [],
                type: 'tech'
            }}
            validationSchema={Yup.object({
                subject: Yup.string().required('Required'),
                message: Yup.string().required('Required'),
                type: Yup.string().required('Required')
            })}
            onSubmit={async ({ subject, message, type, attachments }, { setSubmitting }) => {
                dispatch(setIsSubmitting(true))

                try {
                    const url = `${import.meta.env.VITE_API}client/support`
                    const token = globalCookies.get('XSRF-TOKEN')
                    const formData = new FormData()
                    formData.append('attention', type === 'tech' ? 'Technical Support' : 'Accounts & Billing')
                    formData.append('subject', subject)
                    formData.append('body', message)

                    for (const attachment of attachments) {
                        formData.append('attachments[]', attachment)
                    }

                    const response = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'X-XSRF-TOKEN': token
                        },
                        credentials: 'include',
                        body: formData
                    })

                    const res = await response.json()

                    if (res.messages) {
                        toast.custom(res.messages[0], {
                            duration: 8000
                        })
                    }

                    if (response.ok) {
                        dispatch(setSupportOpen(false))
                    }
                } catch (e) {
                    //
                } finally {
                    dispatch(setIsSubmitting(false))
                    setSubmitting(false)
                }
            }}
        >
            {({ isSubmitting }) => {
                return (
                    <Support.Form id='supportForm'>
                        <Field
                            label={'Support Type'}
                            name={'type'}
                            type={'select'}
                            disabled={isSubmitting}
                            list={[
                                { value: 'tech', label: 'Technical Support' },
                                { value: 'accounts', label: 'Accounts & Billing' }
                            ]}
                            component={NXUIForm.SelectField}
                        />
                        <Field label={'Subject'} name={'subject'} type={'select'} disabled={isSubmitting} component={NXUIForm.InputField} />
                        <Field
                            label={'How can we help you?'}
                            name={'message'}
                            type={'textarea'}
                            disabled={isSubmitting}
                            component={NXUIForm.TextAreaField}
                        />
                        <Field label={'Attachments'} name={'attachments'} disabled={isSubmitting} component={NXUIForm.UploadField} />
                    </Support.Form>
                )
            }}
        </Formik>
    )
}
/**********************************************************************************************************
 *   COMPONENT END
 **********************************************************************************************************/
