import { install as InstallResizeObserver } from 'resize-observer'

export const initialisePolyfills = () => {
    // resize observer
    if (!window.ResizeObserver) {
        InstallResizeObserver()
    }
}

export const initialiseCypress = () => {
    if (import.meta.env.VITE_MOCK_APP === 'enable') {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { worker } = require('mocks/server/dev-server')
        worker.start()

        if (window.Cypress) {
            window.appReady = true
        }
    }
}
