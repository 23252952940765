import { appAPI } from 'api/app'
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

/**********************************************************************************************************
 *   TYPE DEFINITIONS
 **********************************************************************************************************/
export type CaptchaProvider = React.FC<{
    children: React.ReactNode
}>

/**********************************************************************************************************
 *   COMPONENT START
 **********************************************************************************************************/
export const CaptchaProvider: CaptchaProvider = ({ children }) => {
    /***** QUERIES *****/
    const { data: initialiseApplicationData, isLoading: isInitialiseApplicationLoading } = appAPI.useInitialiseApplicationQuery()
    const key = initialiseApplicationData?.settings.provider.captcha_public || ''

    /***** RENDER *****/
    if (isInitialiseApplicationLoading) return <>{children}</>
    return <GoogleReCaptchaProvider reCaptchaKey={key}>{children}</GoogleReCaptchaProvider>
}
