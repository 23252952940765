/**********************************************************************************************************
 *   CUSTOM STYLING
 **********************************************************************************************************/
import { Form } from 'formik'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Anchor, Box, Card, Theme } from 'nxui/src'

/**********************************************************************************************************
 *   INTERFACES / ENUMS
 **********************************************************************************************************/
import { Weight } from 'models/enums'

interface RowProps {
    isOptionChange: boolean
}

export const Services = {
    Base: styled.ul`
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        article {
            margin-bottom: 0;
        }

        td {
            padding: 0;
        }

        section {
            margin-bottom: 0;
        }
    `,
    Links: styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column;
            gap: 10px;
        }

        > button {
            width: 47%;
            justify-content: center;
            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
                width: 100%;
                margin: 0;
            }
        }
    `,
    Link: styled(Link)`
        display: flex;
        flex-direction: column;
        gap: 9px;

        &.active {
            background-color: #eef2ff;
            color: #373179;
        }

        &.last {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }

        > li {
            display: grid;
            gap: 9px;
            padding: 18px;

            &:hover {
                cursor: pointer;
            }
        }
    `,
    BadgeContainer: styled.div`
        display: flex;
        gap: 10px;
    `,

    Details: styled.div`
        display: flex;
        gap: 5px;
    `,
    Name: styled.span`
        color: ${(props) => props.theme['black--100']};
        word-break: break-all;
    `,
    Invoice: styled(Anchor)`
        font-size: 1.4rem;
    `,
    CardHeader: styled.div`
        display: flex;
        gap: 10px;
        padding: 20px;
        flex-wrap: wrap;
        margin-bottom: -20px;
    `
}

export const Service = {
    Base: styled.section`
        height: 100%;
        width: 100%;

        article {
            margin-bottom: 0;
            width: 100%;
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
        }

        div[role='progressbar'] {
            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
                margin-top: 1rem;
            }
        }
    `,
    Empty: styled(Box)`
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
            margin-top: 1rem;
        }

        .info {
            padding: 0 !important;
        }
    `,
    Card: styled(Card)`
        section {
            margin-bottom: 0;
        }

        nav[aria-label='tabs'],
        div[aria-label='select'] {
            margin: 20px;
        }
    `
}

export const Overview = {
    Base: styled(Box)`
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
            margin-top: 16px;
        }

        header {
            width: 100%;

            > div {
                width: 100%;
            }
        }

        footer {
            padding: 12px 24px 12px 0;
            display: flex;
            justify-content: flex-end;
        }
    `,
    Header: styled.div`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column;
            align-items: flex-start;
            gap: 7px;
        }
    `,
    Service: styled.div`
        display: flex;
        gap: 5px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column;
        }
    `,
    Name: styled.span`
        font-size: 1.8rem;
        word-break: break-all;
        ${Theme.Functions.setFont('Inter', Weight.Medium)};
    `,
    Product: styled.span`
        font-size: 1.8rem;
        color: ${(props) => props.theme['text--100']};
        ${Theme.Functions.setFont('Inter', Weight.Regular)};
    `,
    Info: styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid ${(props) => props.theme['gray--100']};
        border-bottom: 1px solid ${(props) => props.theme['gray--100']};
        padding: 25px;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column;
            padding: 0;
        }
    `,
    Column: styled.div`
        width: 20%;
        display: flex;
        flex-direction: row;
        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-xl'])} {
            width: 100%;
        }

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            flex-direction: column;
        }

        &:not(:last-child):after {
            content: '';
            border: 1px solid rgba(209, 213, 219, 0.3);
            align-self: stretch;

            ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
                align-items: center;
            }
        }
    `,
    Row: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.4rem;
        text-align: center;
        flex: 1;
        gap: 10px;

        ${(props) => Theme.Functions.mediaBreakDown(props.theme['breakpoints-sm'])} {
            padding: 20px 0 20px 25px;
            align-items: flex-start;
        }

        > span {
            &:first-child {
                color: ${(props) => props.theme['text--100']};
                ${Theme.Functions.setFont('Inter', Weight.Medium)};
            }

            &:last-child {
                word-break: break-all;
                ${Theme.Functions.setFont('Inter', Weight.Medium)};
            }
        }
    `
}

export const Addon = {
    Base: styled(Box)`
        margin-bottom: 0;

        table {
            tr {
                border: none;
            }
        }
    `,
    Footer: styled.footer`
        display: flex;
        justify-content: flex-end;
        padding: 12px 24px;
        gap: 12px;
        background-color: ${(props) => props.theme['background--100']};
    `,
    Form: styled(Form)`
        width: 100%;

        > h3 {
            padding: 0 0 5px 25px;
        }
    `
}

export const Cancel = {
    Refund: styled.div`
        padding: 19px 1.5rem;
        margin-bottom: 15px;
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        display: flex;
        justify-content: space-between;
        background-color: ${(props) => props.theme['background--200']};
        font-size: 1.4rem;
    `,
    Label: styled.div`
        color: ${(props) => props.theme['text--100']};
    `,
    Value: styled.div`
        color: ${(props) => props.theme['text--200']};
    `
}

export const Change = {
    Base: styled.div`
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 1.4rem;

        svg {
            width: 20px;
            height: 20px;

            &.up {
                color: ${(props) => props.theme['confirm--100']};
            }

            &.down {
                color: ${(props) => props.theme['error--100']};
            }
        }
    `,
    Paths: styled.div`
        select {
            white-space: pre-line;
        }
    `,
    Row: styled.div<RowProps>`
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
            &:first-child {
                width: 80%;
            }

            &:nth-child(2) {
                width: 15%;
                display: flex;
                justify-content: flex-end;
                margin-top: 19px;
            }
        }
    `,
    Label: styled.div`
        display: block;
        margin-bottom: 4px;
        font-size: 1.2rem;
        color: #374151;
        line-height: 1.5;
        ${Theme.Functions.setFont('Inter', Weight.SemiBold)};
    `,
    ConfigDescription: styled.p`
        margin: 10px 0 20px 0;
        color: ${(props) => props.theme['text--100']};
        font-size: 1.4rem;
        line-height: 1.5;
        ${Theme.Functions.setFont('Inter', Weight.Regular)};
        font-weight: 400;
    `,
    BillingCycleField: styled.div`
        margin-bottom: 20px;
    `
}
